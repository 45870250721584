<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Tipo de impresión')"
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
    hide-footer
  >
    <!-- <b-container> -->
      <div class="p-2">
        <b-alert class="p-2" variant="warning" show>Total de envíos seleccionados: <strong>{{ deliveries.length }}</strong></b-alert>
        <b-row class="text-center">
            <b-col>
            <b-button style="min-width: 120px" variant="warning" :disabled="loading.save" @click.prevent="onSave()"><span v-if="!loading.zebra">Zebra</span><b-spinner v-if="loading.zebra" small/></b-button>
            </b-col>
            <b-col>
            <b-button style="min-width: 120px" variant="warning" :disabled="loading.save" @click.prevent="onSave('carta')"><span v-if="!loading.carta">Hoja carta</span><b-spinner v-if="loading.carta" small/></b-button>
            </b-col>
            <b-col>
            <b-button style="min-width: 120px" variant="warning" :disabled="loading.save" @click.prevent="onSave('zpl')"><span v-if="!loading.zpl">ZPL</span><b-spinner v-if="loading.zpl" small/></b-button>
            </b-col>
        </b-row>
      </div>
    <!-- </b-container> -->
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    deliveries: { type: Array }
  },
  data() {
    return {
      loading: {
        save: false,
        zebra: false,
        carta: false,
        zpl: false
      },
      currentType: ''
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading'
    })
  },
  watch: {
    generalLoading: {
      handler () {
        this.loading.save = !!this.generalLoading.postPrintSummaries
        this.loading[this.currentType] = !!this.generalLoading.postPrintSummaries
      },
      deep: true
    }
  },
  methods: {
    onSave(type) {
      this.currentType = type ?? 'zebra'
      this.$emit('saveSummaries', type)
    }
  }
    
}
</script>