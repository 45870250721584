<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Listado de envíos')"
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
  >
    <p class="text-center">Haz click en "Generar cierre {{$t('manifiesto')}}" para imprimirlo (*)</p>
    <div class="mb-2" role="group">
      <b-input-group>
        <b-form-input
        id="input-live"
        v-model="delivery"
        :state="state"
        trim
        aria-describedby="input-live-feedback"
        ></b-form-input>
        <b-input-group-append>
          <b-button
          variant="outline-primary"
          @click="addDelivery"
          >Añadir envío</b-button>
        </b-input-group-append>
        <b-form-invalid-feedback id="input-live-feedback">
        Ocurrio un problema al cargar los datos
        </b-form-invalid-feedback>
      </b-input-group>
    </div>
    <div>
      <table-render
        :schema.sync="schema"
        :rows="rows"
        :stickyHeader="`calc(100vh - 2.5rem)`"
        id="listDeliveriesSummaries"
        :tableClass="'with-scroll'"
        :striped="true"
        :showCheckboxes="true"
        :selectedRows.sync="selectedRows"
      >
      </table-render>
    </div>
    <p class="mt-1">(*) {{$t('msg-manifiesto')}}</p>
    <template #modal-footer>
      <b-button variant="primary" @click.prevent="removeDelivery()" :disabled="loading.remove">
        Mantener manifiesto activo
        <b-spinner v-if="loading.remove" small />
      </b-button>
      <b-button variant="warning" @click.prevent="ok()" :disabled="loading.closeSummary">
        Generar cierre manifiesto
        <b-spinner v-if="loading.closeSummary" small />
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    summary: { type: Object },
    shipper_id: { type: Number }
  },
  data() {
    return {
      rows: [],
      schema: [],
      summary_id: null,
      selectedRows: [],
      delivery: null,
      state: null,
      removeValues: [],
      loading: {
        remove: false,
        closeSummary:false
      }
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading'
    })
  },
  watch: {
    generalLoading: {
      handler () {
        this.loading.remove = !!this.generalLoading.putRemoveDelivery
        this.loading.closeSummary = !!this.generalLoading.putCloseSummaries
      },
      deep: true
    },
    'summary.deliveries'(newValue) {
      this.rows = newValue?.map(delivery => {
        return {
          ...delivery,
          id: delivery?.delivery_id,
          tracking_number: delivery?.tracking_number,
          customer: delivery?.customer?.name,
          comuna: delivery?.destination?.level4
        }
      })
      this.selectedRows = newValue?.map(delivery => delivery?.delivery_id)
    },
    'summary.summary_id'(newValue) {
      this.summary_id = newValue
    },
    selectedRows(newValue) {
      this.removeValues = this.rows.filter((row) => !this.selectedRows.some((value) => value === row.id)).map((row) => Number(row?.id))
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'Nº envío', key: 'imported_id' },
        { label: 'Orden_de_transporte', key: 'tracking_number', sortable: true },
        { label: 'Cliente', key: 'customer', sortable: true },
        { label: 'Place_level3', key: 'comuna', sortable: true }
      ]
    },
    ok () {
      this.closeSummary()
    },
    closeSummary() {
      const data = {
        name: 'putCloseSummaries',
        params: {shipper_id: this.shipper_id },
        queryParams: { summary_id: this.summary_id },
        onSuccess: () => this.successFetch(),
        onError: () => this.loading.closeSummary = false
      }
      this.$store.dispatch('fetchService', data)
    },
    addDelivery() {
      this.state = null
      const data = {
        name: 'getPrintSummaries',
        params: {shipper_id: this.shipper_id },
        queryParams: { deliveries:  [this.delivery]},
        onSuccess: () => {
          this.state = true
          this.successFetch()
        },
        onError: () => this.state = false,
        hideAlert: true
      }
      this.$store.dispatch('fetchService', data)
    },
    removeDelivery() {
      if (this.removeValues?.length === 0) return this.$bvModal.hide('mktSummariesModal')
      const data = {
        name: 'putRemoveDelivery',
        queryParams: { deliveries:  this.removeValues},
        onSuccess: () => this.successFetch(),
        onError: () => this.loading.remove = false
      }
      this.$store.dispatch('fetchService', data)
    },
    successFetch() {
      this.loading.remove = false
      this.$bvModal.hide('mktSummariesModal')
      this.$emit('filterList')
    }
  }
}
</script>