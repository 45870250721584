<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Previsualizador')"
    size="xl"
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
    hide-footer
  >
    <b-tabs align="left" class="tab-primary" v-model="tabIndex">
      <!-- Manifiesto -->
      <b-tab lazy v-if="!!summary_url">
        <template #title>
          <div>
            <span> {{$t('Manifiesto')}} </span>
          </div>
        </template>
        <div>
          <div class="mt-2">
            <iframe width="100%" height="700" :src="this.summary_url"></iframe>
          </div>
          <div class="">
            * Si no puedes previsualizar el PDF haz <b-link :href="this.summary_url" target="_black">click aquí</b-link>
          </div>
        </div>
      </b-tab>
      <!-- Etiquetas -->
      <b-tab lazy v-if="!!labels_url">
        <template #title>
          <div>
            <span> {{$t('Etiquetas')}} </span>
          </div>
        </template>
        <div>
          <div class="mt-2">
            <iframe width="100%" height="700" :src="this.labels_url"></iframe>
          </div>
          <div class="">
            * Si no puedes previsualizar el PDF haz <b-link :href="this.labels_url" target="_black">click aquí</b-link>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
<script>
import { BLink } from 'bootstrap-vue'
export default {
  components: { BLink },
  props: {
    summary_url: { type: String, default: '' },
    labels_url: { type: String, default: '' }
  },
  data() {
    return {
      tabIndex: 0
    }
  }
}
</script>