<template>
  <div>
    <filter-swapper v-if="fieldsSelect.length !== 0" :buttons="buttons" :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render :form.sync="formSelect" :key="keyFormRender" :fields="fieldsSelect" @send="filterList" :buttonSend="buttonSend" ref="formFilter"
          containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2" class="mb-2">
          <template #buttons>
            <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
          </template>
        </form-render>
      </template>
    </filter-swapper>
    <!-- botones secundarios -->
    <div class="mb-1 px-1 row justify-content-between">
      <div v-show="!loading.first">
        <b-button @click="removeDeliveries(selectedRows)" variant="outline-danger" v-b-tooltip.hover :title="$t('Eliminar todos los envíos')" :disabled="!selectedRows.length">
          <feather-icon icon="Trash2Icon" size="16"/>
        </b-button>
        <b-button variant="warning" @click="openModal('modalTypePrint')" v-b-tooltip.hover :title="$t('Generar etiqueta')"  class="mx-1" :disabled="!selectedRows.length">
          <feather-icon icon="PrinterIcon" size="16"/>
        </b-button>
        <span class="ml-1">
          <strong>{{selectedRows.length}} envíos seleccionados</strong>
        </span>
      </div>
      <div class="mt-1 mt-md-0">
        <b-button variant="warning" :disabled="!enableButtonMulti" @click="openModal('modalMultiSearch')" v-b-tooltip.hover :title="$t('Búsqueda múltiple de envíos')">Multiselección</b-button>
      </div>
    </div>
    <div v-if="loading.second && rows.length===0" class="d-flex flex-column justify-content-center align-items-center" style="height: 470px">
      <div class="col-lg-6">
        <div class="text-center">
          <feather-icon class="text-center" icon="FileTextIcon" size="52" style="color:#B9B9C7"/>
        </div>
        <div class="text-wrap text-center my-1" style="font-size:14px;color:#B9B9C7">
          Utiliza los selectores disponibles en la parte superior para realizar una búsqueda eficiente y acceder a la información deseada.
        </div>
      </div>
    </div>
    <div v-show="!loading.total" class="table-listlabels">
      <table-render
        :schema.sync="schema"
        :rows="rows"
        :actions="actions"
        :loading="loading.deliveries"
        :stickyHeader="`calc(100vh - 2.5rem)`"
        id="listDeliveriesPrinter"
        :tableClass="'with-scroll'"
        :striped="true"
        :showCheckboxes="true"
        :selectedRows.sync="selectedRows"
      >
        <template #delivery_id="scope">
          <a
            class="underline-text"
            :href="generateUrl(scope.rowdata.id)"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ scope.rowdata.delivery_id }}
          </a>
        </template>
        <template #status_name="scope">
          <span :class="`table_dot--state ${$options.filters.getStatusClasses()[getStatusDeliveryId(scope.rowdata.status)]}`"></span>&nbsp; {{scope.rowdata.status_name.name}}
        </template>
      </table-render>
      <pagination :pagination="pagination" :noDigits="false" :showSize="true"/>
    </div>
    <div class="mt-1" v-if="loading.deliveries">
      <b-skeleton-table :rows="6" :columns="schema.length" :table-props="{}" />
    </div>
    <modal-multi-search @filter="getDeliveriesFilter" ref="modalSearch"/>
    <modal-type-print
    id="modalTypePrint"
    @saveSummaries="saveSummaries"
    :deliveries="selectedRows"
    ></modal-type-print>
    <modal-document
    id="printerDocumentModal"
    :labels_url="labels"
    >
    </modal-document>
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapGetters } from 'vuex'
import ModalTypePrint from './ModalTypePrint.vue'
import ModalMultiSearch from '../../deliveries/create/generate-labels/ModalMultiSearch.vue'
import ModalDocument from './ModalDocument.vue'
export default {
  components: { ModalTypePrint, FeatherIcon, ModalMultiSearch, ModalDocument },
  data() {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      buttons: [],
      keyFormRender: 0,
      fieldsSelect: [],
      formSelect: {},
      shipper_id: null,
      organization_id: null,
      schema: [],
      rows: [],
      actions: [],
      selectedRows: [],
      mkcOption: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      loading: {
        deliveries: false,
        total: true,
        second: true
      },
      labels: ''
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizations',
      shippersByOrganization: 'getShippersByOrganization',
      deliveries: 'getPrinterDeliveries',
      marketcenters: 'getMarketcenterByShipperId',
      generalLoading: 'getLoading',
      document: 'getPrintSummaries'
    }),
    disabled() {
      if (this.shipper_id) return false
      return true
    },
    isUserAdmin() {
      return ['admin'].includes(this.mySession.role)
    },
    enableButtonMulti() {
      if (this.isUserAdmin) return Boolean(this.shipper_id) && Boolean(this.organization_id)
      return Boolean(this.shipper_id)
    }
  },
  watch: {
    marketcenters(val) {
      this.mkcOption = val?.map((op) => ({ id: op.code, text: op.name })) || []
      this.setSelectOptions('marketcenter', { options: this.mkcOption })
    },
    deliveries() {
      this.pagination.total = this.deliveries?.total_pages * this.deliveries?.limit ?? 0
      this.rows = this.deliveries?.deliveries?.map((delivery) => {
        return {
          ...delivery,
          id: delivery?.santiago_identifier,
          commerce: delivery?.commerce,
          status_name: delivery?.status[delivery?.status?.length - 1],
          tracking_number: delivery?.tracking_number,
          marketplace: delivery?.marketplace,
          place: this.setPlace(delivery?.destination?.place),
          created_at: this.$options.filters.dbDateUTCToGMTlocal(delivery?.created_at)
        }
      })
      this.loading.second = false
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations.rows })
    },
    shippersByOrganization () {
      const shipperOptions = this.shippersByOrganization.map((shipper) => ({...shipper, name: shipper.name1, text: shipper.name1 }))
      this.setSelectOptions('shipper_id', { options: shipperOptions })
    },
    generalLoading: {
      handler () {
        this.loading.deliveries = !!this.generalLoading.getPrinterDeliveries

        this.setSkeletonField('shipper_id', !!this.generalLoading.getShippersByOrganization)
        this.setSkeletonField('marketcenter', !!this.generalLoading.getMarketcenterByShipperId)
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },
    'pagination.page' () {
      this.filterList()
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.filterList()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      if (['admin'].includes(this.mySession.role)) {
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'eCommerce/Marketplace', useLabel: true, clearable: true, change: this.changeOrganization, validation: 'required', persistSearch: true, searchOnType: { fx: e => this.getOrganizationsFetch(e), nChars: 3, debounce: 600 }, options: []},
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, dependency: 'organization_id', change: this.changeShipper, validation: 'required' }
        ]
      } else if (['marketplace'].includes(this.mySession.role)) {
        this.loading.first = false
        this.organizationId = this.mySession.organization.id
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, change: this.changeShipper, validation: 'required' }
        ]
        this.changeOrganization('Organization', this.mySession.organization)
      } else {
        this.loading.first = false
        this.shipper_id = this.mySession?.shipper?.id
        this.filterList()
      }

      this.fieldsSelect.push(
        { fieldType: 'FieldSelect', label: 'Integración (mkp)', name: 'marketcenter', useLabel: true, clearable: true},
        { fieldType: 'FieldDatepicker', name: 'last_message', label: 'Fecha de creación', clearable: true, range: true},
        { fieldType: 'FieldInput', label: 'OT/Referencia', name: 'tracking-number'}
      )

      this.schema = [
        {label: 'Comercio', key: 'commerce'},
        {label: 'Estado', key: 'status_name', sortable: true, useSlot: true},
        {label: 'Nº de envío', key: 'delivery_id', useSlot: true},
        {label: 'OT/Referencia', key: 'tracking_number', sortable: true},
        {label: 'Place_level3', key: 'place', sortable: true},
        {label: 'Carrier', key: 'carrier_name', sortable: true},
        {label: 'Marketplace', key: 'marketplace', sortable: true},
        {label: 'Fecha de creacion', key: 'created_at', sortable: true},
        {label: 'Acciones', key: 'actions', class: ['text-center']}
      ]

      this.actions = [
        {action: id => this.removeDeliveries([id]), icon: 'TrashIcon', color: 'danger', text: 'Eliminar envío' }
      ]
    },
    getOrganizationsFetch(name) {
      const data = { 
        name: 'getOrganizations',
        queryParams: {name, simplified: true }
      }
      this.$store.dispatch('fetchService', data)
    },
    filterList(_, multiSelect) {
      this.loading.second = false
      const shipper_id = this.shipper_id
      const filters = {}
      if (this.formSelect?.marketcenter) filters.marketcenter_code = this.formSelect?.marketcenter?.id
      if (this.formSelect?.date) filters.date = this.$options.filters.moment(this.formSelect?.date, 'YYYY-MM-DD')
      if (!!this.formSelect['tracking-number']) filters.reference = this.formSelect['tracking-number']
      if (this.formSelect?.last_message) {
        filters.start_date = this.$options.filters.moment(this.formSelect?.last_message?.start, 'YYYY-MM-DD')
        filters.end_date = this.$options.filters.moment(this.formSelect?.last_message?.end, 'YYYY-MM-DD')
      }
      if (!!multiSelect) filters.reference = multiSelect?.number?.split(',').map((num) => num.trim()).join(',')
      const data = {
        name: 'getPrinterDeliveries',
        params: {
          shipper_id,
          limit: this.pagination.limit,
          page: this.pagination.page
        },
        queryParams: {
          ...filters
        },
        onSuccess: () => { if (!!multiSelect) this.$refs.modalSearch.resetForm() },
        onError: () => { if (!!multiSelect) this.$refs.modalSearch.resetForm() }
      }
      this.$store.dispatch('fetchService', data)
    },
    changeOrganization (name, value) {
      this.organization_id = value.id
      this.getOrganizationByShipper(value.id)
    },
    getOrganizationByShipper(organization_id) {
      const data = { 
        name: 'getShippersByOrganization',
        queryParams: {page: 1, paginate_by: 99999 },
        params: { organization_id },
        onError: () => {
          this.setSelectOptions('shipper_id', { options: [] })
          this.setSkeletonField('shipper_id', false)
        },
        hideAlert: true
      }
      this.$store.dispatch('fetchService', data)
    },
    getMarketcenters() {
      const data = {
        name: 'getMarketcenterByShipperId',
        params: { shipper_id: this.shipper_id }
      }
      this.$store.dispatch('fetchService', data)
    },
    cleanFilter (e) {
      this.formSelect = {}
      this.shipper_id = null
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsSelect[index].options = options
        if (!['organization_id'].includes(name)) this.keyFormRender++
      }
    },
    changeShipper(_, value) {
      this.shipper_id = value.id
    },
    saveSummaries(type) {
      const queryParams = { deliveries:  this.selectedRows }
      if (type) queryParams.type = type
      const data = {
        name: 'postPrintSummaries',
        params: {shipper_id: this.shipper_id },
        queryParams,
        onSuccess: (res) => { 
          this.selectedRows = []
          this.filterList()
          this.$bvModal.hide('modalTypePrint')
          this.$success(this.$t('Manifiesto se ha creado con éxito.'))
          if (type !== 'zpl') {
            this.labels = res?.data[0]?.labels
            this.$bvModal.show('printerDocumentModal')
          }
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    removeDeliveries(deliveries) {
      const isMultiple = deliveries.length > 1
      const data = {
        name: 'deleteDeliveryMkc',
        params: {shipper_id: this.shipper_id },
        queryParams: { deliveries: [...deliveries] },
        onSuccess: () => { 
          this.m_closeAlertLoading()
          this.$bvToast.toast(`Envio: ${deliveries.toString()} eliminado correctamente.`, {
            title: 'Éxito',
            variant: 'success',
            solid: true
          })
          this.filterList()
          this.selectedRows = []
        },
        onError: () => this.m_closeAlertLoading()
      }
      this.$confirm(
        isMultiple ? 'Antes de eliminar los envíos se verificará que no esten en movimiento, de ser así los envíos en movimiento no se eliminaran.<br><br>La operación puede tomar varios minutos.' : null,
        () => {
          const word = isMultiple ? 'envíos' : 'envío' 
          this.$loadingAlert({text: `Eliminando ${word}: ${deliveries.toString()}, por favor espere...`, color: 'primary'})
          this.$store.dispatch('fetchService', data)
        },
        isMultiple ? this.$t('msg-pregunta-eliminar-envios') : this.$t('msg-pregunta-eliminar-envio')
      )
    },
    openModal(id) {
      this.$bvModal.show(id)
    },
    setPlace(place) {
      switch (place.level1) {
      case 'Chile':
        return place?.level3
      case 'Peru':
        return place?.level3
      case 'Colombia':
        return place?.level3
      default:
        return place?.level4 ?? null
      }
    },
    setSkeletonField(name, value) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) this.fieldsSelect[index].useSkeleton = value
    },
    getDeliveriesFilter(data) {
      this.formSelect = {}
      this.filterList(null, data)
    },
    getStatusDeliveryId(logs) {
      return logs?.at(-1)?.id
    },
    generateUrl(id) {
      return this.$router.resolve({ name: 'deliveries-details', params: { id } }).href
    }
  }
}
</script>

<style>
.table-listlabels > .table-render-skeleton {
  overflow-x: hidden !important;
}
.underline-text{
  text-decoration: underline !important;
}
</style>